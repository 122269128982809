const INIT_STATE = {
  Show: true
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case "Footer_Toogle":
      return { ...state, Show: payload };
    default:
      return state;
  }
};
