import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
// import ReactGA from "react-ga";
// import ReactPixel from "react-facebook-pixel";

import LocalizedStrings from "react-localization";

let englishStrings = require("./PrivacyPolicy_langs_en.json");

let strings = new LocalizedStrings({ en: englishStrings });

export class NewPrivacyPolicy extends Component {
  render() {
    // var locale =
    //   this.props.match.params.locale &&
    //   this.props.match.params.locale.split("-").length === 2
    //     ? this.props.match.params.locale
    //     : "en-us";

    let language = "en";
    // let country = locale? locale.split('-')[1]:'us';
    strings.setLanguage(language);

    let flipImage = language === "ar" ? "scaleX(-1)" : "none";

    return (
      <div className="container">
        <Helmet>
          <title>{strings.meta_privacy_title}</title>
          <meta name="robots" content="noindex, nofollow" />
          <link
            rel="alternate"
            href="https://onetwosmile/en-sa/privacy-policy"
            hreflang="en-sa"
          />
          <link
            rel="alternate"
            href="https://onetwosmile/en-jo/privacy-policy"
            hreflang="en-jo"
          />
          <link
            rel="alternate"
            href="https://onetwosmile/en-kw/privacy-policy"
            hreflang="en-kw"
          />
          <link
            rel="alternate"
            href="https://onetwosmile/en-qa/privacy-policy"
            hreflang="en-qa"
          />
          <link
            rel="alternate"
            href="https://onetwosmile/en-us/privacy-policy"
            hreflang="en-us"
          />
          <link
            rel="alternate"
            href="https://onetwosmile/en-eu/privacy-policy"
            hreflang="en-de"
          />
          <link
            rel="alternate"
            href="https://onetwosmile/en-iq/privacy-policy"
            hreflang="en-iq"
          />
          <link
            rel="alternate"
            href="https://onetwosmile/en-lb/privacy-policy"
            hreflang="en-lb"
          />

          <link
            rel="alternate"
            href="https://onetwosmile/ar-sa/privacy-policy"
            hreflang="ar-sa"
          />
          <link
            rel="alternate"
            href="https://onetwosmile/ar-jo/privacy-policy"
            hreflang="ar-jo"
          />
          <link
            rel="alternate"
            href="https://onetwosmile/ar-kw/privacy-policy"
            hreflang="ar-kw"
          />
          <link
            rel="alternate"
            href="https://onetwosmile/ar-qa/privacy-policy"
            hreflang="ar-qa"
          />
          <link
            rel="alternate"
            href="https://onetwosmile/ar-us/privacy-policy"
            hreflang="ar-us"
          />
          <link
            rel="alternate"
            href="https://onetwosmile/ar-eu/privacy-policy"
            hreflang="ar-de"
          />
          <link
            rel="alternate"
            href="https://onetwosmile/ar-iq/privacy-policy"
            hreflang="ar-iq"
          />
          <link
            rel="alternate"
            href="https://onetwosmile/ar-lb/privacy-policy"
            hreflang="ar-lb"
          />
        </Helmet>

        {/* Section A - Cover */}
        <section className="grid cover">
          <div
            className="cover-image"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL}/covers/privacy.jpg)`,
              transform: flipImage,
            }}
          />
          <div className="cover-text">
            <h1>{strings.privacy_cover_h1}</h1>
          </div>
        </section>

        <section className="grid" id="section-b">
          <div className="policy-container">
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>Privacy Policy</h2>
            <p style={{ fontSize: "14px" }}>
              This Privacy Policy describes how OneTwoSmile collects and uses
              Personal Data about you through the use of our Website, mobile
              applications, and through email, text, and other electronic
              communications between you and OneTwoSmile.
            </p>
            &nbsp;
            <p style={{ fontSize: "14px" }}>
              OneTwoSmile (“OneTwoSmile” or “we” or “our”) respects your privacy
              and is committed to protecting it through our compliance with this
              policy.
            </p>
            &nbsp;
            <p style={{ fontSize: "14px" }}>
              This policy applies to information we collect:
            </p>
            <p style={{ fontSize: "14px" }}>
              - from you on or through our Website, Application and Social
              Media;
            </p>
            <p style={{ fontSize: "14px" }}>
              - from or on behalf of your licensed dentist, orthodontist or
              other medical provider or her/his staff;
            </p>
            <p style={{ fontSize: "14px" }}>
              - in email, text, and other electronic messages between you and
              our Website or Application;
            </p>
            <p style={{ fontSize: "14px" }}>
              - through printed documents, records, and materials; and
            </p>
            <p style={{ fontSize: "14px" }}>
              when you interact with our advertising, marketing, and
              applications on third-party websites and services, if those
              applications or advertising include links to this policy.
            </p>
            &nbsp;
            <p style={{ fontSize: "14px" }}>
              Please read this policy carefully to understand our policies and
              practices regarding your information and how we will treat it. If
              you do not agree with our policies and practices, you are not
              permitted to use our Website or Application. By accessing or using
              our Website or Application, you agree to the terms and conditions
              of this Privacy Policy. This Privacy Policy may change from time
              to time. Your continued use of our Website or Application after we
              make changes shall constitute your acceptance of those changes.
            </p>
            <h2 style={{ fontSize: "22px" }}>
              Data We Collect About You and How We Collect It
            </h2>
            <p style={{ fontSize: "14px" }}>
              We collect different types of information and data about you,
              including information and data that may directly identify you or
              be used to identify you, information or data that is about you but
              individually does not personally identify you, and information and
              data that we combine with our other users. This includes
              information and data that we collect directly from you or through
              automated collection technologies.
            </p>
            &nbsp;
            <p style={{ fontSize: "14px" }}>
              We collect several types of information and data from and about
              users of our Application and Website, specifically information and
              data:
            </p>
            <p style={{ fontSize: "14px" }}>
              - such as name; home, shipping, and postal address; e-mail
              address; home and mobile telephone number; credit card, debit
              card, or other payment information (solely for payment purposes);
              gender; date of birth; marital status; your medical history and
              name(s) of your medical and dental providers; identifying numbers;
              and other information about your past, present or future physical
              or mental health condition or the provision of health or dental
              care; that is about you but individually does not identify you,
              such as statistical and other communication data and the resources
              that you access and use on the Website and in the Applications;
              and/or about your internet connection and the equipment you use to
              access our Website and Application and usage details.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>
              How We collect this information:
            </h2>
            <p style={{ fontSize: "14px" }}>
              - directly from you when you provide it to us; automatically as
              you navigate through the Website or use the Application.
              Information collected automatically may include usage details, IP
              addresses, and information collected through cookies, web beacons,
              and other tracking technologies; and From third parties, for
              example, your health and dental providers and our business
              partners.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>Data You Provide Us</h2>
            <p style={{ fontSize: "14px" }}>
              The information and data we collect on or through our Website and
              Application are:
            </p>
            <p style={{ fontSize: "14px" }}>
              - information and data that you provide by filling in forms on our
              Website and Application. This includes information and data
              provided at the time of registering to use our Website or
              Application, subscribing to our service, posting material, or
              requesting further services. We may also ask you for information
              when you enter a contest or promotion sponsored by us, or during a
              study you consent to participate in, and when you report a problem
              with our Website or Application;
            </p>
            <p style={{ fontSize: "14px" }}>
              - records and copies of your correspondence (including email
              addresses), if you contact us;
            </p>
            <p style={{ fontSize: "14px" }}>
              - your responses to surveys that we might ask you to complete for
              research purposes; and
            </p>
            <p style={{ fontSize: "14px" }}>
              - details of transactions you carry out through our Application
              and Website and of the fulfillment of your orders. You may be
              required to provide financial information before placing an order
              through our Website or our Application.
            </p>
            <p style={{ fontSize: "14px" }}>
              The Personal Data we collect from you is collected with your
              consent and required for us to provide you with our products and
              services and to facilitate communication between you and your
              licensed dental provider. You may withdraw your consent at any
              time by terminating your membership with us; however, we may no
              longer be able to provide you with our goods and services if you
              do.
            </p>
            <p style={{ fontSize: "14px" }}>
              You also may provide information to be published or displayed
              (hereinafter, “posted”) on public areas of the Website and
              Application or transmitted to other users of the Website or third
              parties (collectively, “User Contributions”). Your User
              Contributions are posted on and transmitted to others at your own
              risk. Although we limit access to certain pages, please be aware
              that no security measures are perfect or impenetrable.
              Additionally, we cannot control the actions of other users of the
              Website or Application with whom you may choose to share your User
              Contributions. Therefore, we cannot and do not guarantee that your
              User Contributions will not be viewed by unauthorized persons.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>How We Use Your Data</h2>
            <p style={{ fontSize: "14px" }}>
              - provide our Application and Website to you;
            </p>
            <p style={{ fontSize: "14px" }}>
              We use your Personal Data for various purposes described below,
              including to:
            </p>
            <p style={{ fontSize: "14px" }}>
              - provide you with information you request from us and your
              licensed dental provider;
            </p>
            <p style={{ fontSize: "14px" }}>
              - enforce our rights arising from contracts;
            </p>
            <p style={{ fontSize: "14px" }}>- notify you about changes; and</p>
            <p style={{ fontSize: "14px" }}>
              provide you with notices about your account.
            </p>
            <p style={{ fontSize: "14px" }}>
              We use information that we collect or receive about you or that
              you provide to us, including any Personal Data, for the following
              purposes:{" "}
            </p>
            <p style={{ fontSize: "14px" }}>
              - to present our Website and Application and their contents to{" "}
              you;
            </p>
            <p style={{ fontSize: "14px" }}>
              - to provide your licensed dental provider and you with
              information, products, or services that your provider or you
              request from us;
            </p>
            <p style={{ fontSize: "14px" }}>
              - to fulfill any other lawful purpose for which you provide it;
            </p>
            <p style={{ fontSize: "14px" }}>
              - to provide you with notices about your membership, including
              expiration and renewal notices;
            </p>
            <p style={{ fontSize: "14px" }}>- to authenticate your identity;</p>
            <p style={{ fontSize: "14px" }}>
              - to monitor your compliance with any of your agreements with us;
            </p>
            <p style={{ fontSize: "14px" }}>
              - to carry out our obligations and enforce our rights arising from
              any contracts entered into between you and us, including for
              billing and collection;
            </p>
            <p style={{ fontSize: "14px" }}>
              - to notify you about changes to our Website or Application or any
              products or services we offer or provide though them;
            </p>
            <p style={{ fontSize: "14px" }}>
              - to allow you to participate in interactive features on our
              Website and Application;
            </p>
            <p style={{ fontSize: "14px" }}>
              - to allow you to participate in, and us to administer, our
              contests, promotions and surveys;
            </p>
            <p style={{ fontSize: "14px" }}>
              - to manage or transfer our assets or liabilities, for example in
              the case of an actual or potential acquisition, disposition or
              merger;
            </p>
            <p style={{ fontSize: "14px" }}>
              - in any other way we may describe when you provide the
              information; and for any other purpose with your consent, or as
              otherwise required or permitted by applicable law.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>Disclosure of Your Data </h2>
            <p style={{ fontSize: "14px" }}>
              We do not share, sell, or otherwise disclose your Personal Data
              for purposes other than those outlined in this Privacy Policy. We
              disclose your Personal Data to a few third parties, including:
            </p>
            &nbsp;
            <p style={{ fontSize: "14px" }}>
              - Your licensed dental provider;
            </p>{" "}
            <p style={{ fontSize: "14px" }}>
              - our subsidiaries and affiliates;
            </p>{" "}
            <p style={{ fontSize: "14px" }}>
              - our third-party service providers, subcontractors, and business
              associates we use to support our business;
            </p>{" "}
            <p style={{ fontSize: "14px" }}>
              - to any company we might merge with or acquire, or that buys us,
              or in the event of change in structure of our company of any form;
            </p>{" "}
            <p style={{ fontSize: "14px" }}>
              - o comply with our legal obligations;
            </p>{" "}
            <p style={{ fontSize: "14px" }}>
              - to enforce our rights; and with your consent.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>Health Data </h2>
            <p style={{ fontSize: "14px" }}>
              We may process some Personal Data considered sensitive when
              necessary to carry out our obligations to you. Some Personal Data
              processed by OneTwoSmile may be considered sensitive and/or
              protected, including Personal Data concerning your health.
              SmileDirectClub processes this information only to the extent
              necessary to carry out its obligations to your licensed dental
              care provider and you, and to provide your licensed dental
              provider and you with our goods and services.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>Data subject rights </h2>
            <p style={{ fontSize: "14px" }}>
              At anytime, data subjects can contact OneTwoSmile in order to
              exercise the following rights:
            </p>
            &nbsp;
            <p style={{ fontSize: "14px" }}>
              - Right to access to their personal data
            </p>
            <p style={{ fontSize: "14px" }}>
              - Right to modify or erase their personal data, subject to the
              legal requirements applicable in the data subject’s country
            </p>
            <p style={{ fontSize: "14px" }}>
              - Right to restrict to personal data processing
            </p>
            <p style={{ fontSize: "14px" }}>
              - Right to oppose to personal data processing
            </p>
            <p style={{ fontSize: "14px" }}>
              - Right to personal data portability
            </p>
            &nbsp;
            <p style={{ fontSize: "14px" }}>
              To exercise these rights, data subjects are invited to contact
              OneTwoSmile by email, at info@onetwosmile.com
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>
              Changes to Agreement and Additional Rules of Usage{" "}
            </h2>
            <p style={{ fontSize: "14px" }}>
              Policies and Terms & Conditions may be changed or updated
              occasionally to meet the requirements and standards. Therefore the
              Customers’ are encouraged to frequently visit these sections in
              order to be updated about the changes on the website.
              Modifications will be effective on the day they are posted.
            </p>
            &nbsp;
            <p style={{ fontSize: "14px" }}>
              OneTwoSmile also reserves the right to post, from time to time,
              additional rules of usage that apply to specific parts of the
              Site, which may be posted in the relevant parts of the Site, and
              will be clearly identified. Your continued use of the Site
              constitutes your agreement to comply with these additional rules.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>
              Suspension or Termination of Your Use
            </h2>
            <p style={{ fontSize: "14px" }}>
              Your failure to follow the requirements of this Agreement may
              result in suspension or termination of your access to the
              Services, without notice, in addition to OneTwoSmile’s other
              remedies. OneTwoSmile further reserves the right to terminate,
              without notice, any user's access to or use of the Site for any
              reason.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>
              Site Information is Provided "As Is"
            </h2>
            <p style={{ fontSize: "14px" }}>
              Site Information is provided "as is" with all faults. You use the
              Site Information, as authorized herein, at your own risk. Site
              Information may contain errors, omissions, or typographical errors
              or may be out of date. The Site may change, delete or update any
              Site Information at any time and without prior notice.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>Use of the Service By You</h2>
            <p style={{ fontSize: "14px" }}>
              You may view and use the Site Information only for your personal
              information and for shopping and ordering on the Site. Except as
              set forth in this Agreement, the Site does not grant to you any
              right to use, reproduce, copy, modify, transfer, display, publish,
              sell, license, create derivative works, publicly perform, or
              distribute by any means, method or process any Site Information.
              Your right to use the Site and the Services is personal to you --
              you may not authorize others to use the Site and the Services, and
              you are responsible for all use of the Site and the Services by
              you and by those you allow to use, or provide access to, the Site
              and the Services. You agree to use the Site and the Services only
              for lawful purposes and you acknowledge that your failure to do so
              may subject you to civil and criminal liability.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>Credit Card Data Storage</h2>
            <p style={{ fontSize: "14px" }}>
              All credit/debit cards details and personally identifiable
              information will NOT be stored, sold, shared, rented or leased to
              any third parties
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>3rd Party Advertisers</h2>
            <p style={{ fontSize: "14px" }}>
              Some of the advertisements you see on the Site are selected and
              delivered by third parties, such as ad networks, advertising
              agencies, advertisers, and audience segment providers. These third
              parties may collect information about you and your online
              activities, either on the Site or on other websites, through
              cookies, web beacons, and other technologies in an effort to
              understand your interests and deliver to you advertisements that
              are tailored to your interests. Please remember that we do not
              have access to, or control over, the information these third
              parties may collect. The information practices of these third
              parties are not covered by this privacy policy
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>Non-Commercial Use Only</h2>
            <p style={{ fontSize: "14px" }}>
              The Site is for the personal use of individuals only and may not
              be used in connection with any commercial endeavors.
              Organizations, companies and/or businesses may not use the
              Services, the Site Information or the Site for any purpose without
              written consent of or agreement with OneTwoSmile. You agree not to
              make use of Site Information by publication, re-transmission,
              distribution, performance, caching, or otherwise, except as
              permitted by law or as expressly permitted in writing by this
              Agreement or OneTwoSmile. Illegal and/or unauthorized uses of the
              Site, including efforts to collect usernames and/or email
              addresses of users by electronic or other means for the purpose of
              sending unsolicited email and unauthorized framing of or linking
              to the Site, will be investigated and appropriate legal action
              will be taken, including without limitation, civil, criminal and
              injunctive redress.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>
              Eligibility to Use the Site and the Services
            </h2>
            <p style={{ fontSize: "14px" }}>
              The Site and the Services are not intended for users under the age
              of 18. To register for any Services offered on the Site, you must
              be 18 years of age or older. If you are a minor under the age of
              18, you may only use the Site in conjunction with your parents or
              guardians. OneTwoSmile does not knowingly collect personally
              identifiable information from users under the age of 13. Children
              under 13 should not use the Site. If a child under 13 submits
              information through any part of the Site, and OneTwoSmile becomes
              aware that the person submitting the information is a child, we
              will attempt to delete this information as soon as possible.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>Freight Term</h2>
            <p style={{ fontSize: "14px" }}>
              Unless otherwise agreed products shall be shipped "F.O.B Origin"
              (OneTwoSmile’s warehouse), regardless of prepaid freight by
              OneTwoSmile or express freight paid by the customer. Title to and
              risk of loss to products shall pass to Buyer upon shipment.
              Neither the time method nor place of payment, method of shipment,
              form of shipping document, nor place of acceptance of Buyer's
              order shall alter the foregoing.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>
              Disclaimer of Warranty and Limitation of Liability
            </h2>
            <p style={{ fontSize: "14px" }}>
              You acknowledge that you are using the Website and the services at
              your own risk. The Website, the Website information and the
              services are provided "as is," and to the full extent permitted by
              applicable law, OneTwoSmile, its affiliates and its third party
              service providers hereby expressly disclaim any and all
              warranties, express and implied, including, without limitation,
              any warranties of accuracy, completeness or reliability, title,
              noninfringement, merchantability or fitness for a particular
              purpose, or any other warranty, condition, guarantee or
              representation, whether oral, in writing or in electronic form.
              OneTwoSmile, its affiliates, and its third party service providers
              do not represent or warrant that access to the Website and its
              services will be uninterrupted or that there will be no failures,
              errors or omissions, or loss or security breach of transmitted
              information, or that no viruses will be transmitted through access
              to or use of onetwosmile.com.
            </p>
            &nbsp;
            <p style={{ fontSize: "14px" }}>
              OneTwoSmile its affiliates and its third party service providers
              shall not be liable to you or any third parties for any direct,
              indirect, special, consequential or punitive damages arising out
              of this agreement, the provision of services hereunder, the sale
              or purchase of any merchandise ordered through the Website, your
              access to or inability to access the Website, Website information,
              or services, including for viruses alleged to have been obtained
              from the services, your use of or reliance on the services, the
              Website information or materials available through third party
              Websites linked to the Website, regardless of the type of claim or
              the nature of the cause of action, even if advised of the
              possibility of such damages. Some states do not allow the
              exclusion of implied warranties or the limitation or exclusion of
              liability for incidental or consequential damages, so the above
              exclusions or limitations may not apply to you. You may also have
              other rights that vary from state to state.
            </p>
            &nbsp;
            <p style={{ fontSize: "14px" }}>
              You hereby agree to release OneTwoSmile, its affiliates and
              third-party service providers, and each of their respective
              directors, officers, employees, and agents from any and all
              claims, demands and damages (actual and consequential) of every
              kind and nature, known and unknown, suspected and unsuspected,
              disclosed and undisclosed ("claims"), arising out of or in any way
              connected with your use of this Website and its services.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>Electronic Communications</h2>
            <p style={{ fontSize: "14px" }}>
              When you visit the Website, Application or send emails to us, you
              are communicating with us electronically. You consent to receive
              communications from us electronically. We will communicate with
              you by email or by posting notices on the Site. You agree that all
              agreements, notices, disclosures and other communications that we
              provide to you electronically satisfy any legal requirement that
              such communications be in writing.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>Payments</h2>
            <p style={{ fontSize: "14px" }}>
              If you make a payment for our products or services on our Website,
              the details you are asked to submit will be provided directly to
              our payment provider via a secure connection.
            </p>
            &nbsp;
            <p style={{ fontSize: "14px" }}>
              The cardholder must retain a copy of transaction records and
              Merchant policies and rules.
            </p>
            &nbsp;
            <p style={{ fontSize: "14px" }}>
              Multiple shipments/delivery may result in multiple postings to the
              Cardholder’s monthly statement
            </p>
            &nbsp;
            <p style={{ fontSize: "14px" }}>
              We deliver to all international addresses. We can either deliver
              the aligners to your home or office or to the clinic where you did
              your original consultation. Delivery of the aligners or any
              subsequent steps takes between 10 to 14 days after receiving the
              payment for the aligners or the steps in the case of paying in
              instalments. There are no additional delivery charges.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>Cancellation and refund policy</h2>
            <p style={{ fontSize: "14px" }}>
              Your custom made OneTwoSmile clear aligners can be returned within
              the first 30 days of your smile journey if you’re not happy for
              any reason. It’s that simple. And we’re that confident. Just
              remember, you’re responsible for any shipping charges when
              returning your invisible aligners. The 30-day time period begins
              the day your invisible aligner shipment is delivered.
            </p>
            &nbsp;
            <p style={{ fontSize: "14px" }}>
              Refunds will be done only through the original Mode of payment
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>Trademark Notice</h2>
            <p style={{ fontSize: "14px" }}>
              OneTwoSmile and the Site names, logos, and other identifying marks
              are the property of OneTwoSmile. Other featured words or symbols,
              used to identify the source of merchandise or services, may be the
              trademarks of their respective owners.
            </p>
            &nbsp;
            <h2 style={{ fontSize: "22px" }}>Contact Information</h2>
            <p style={{ fontSize: "14px" }}>
              You may contact us through the contact information below.
            </p>
            &nbsp;
            <p style={{ fontSize: "14px" }}>
              If you have any questions, concerns, complaints or suggestions
              regarding our Privacy Policy, have any requests related to your
              Personal Data, or otherwise need to contact us, you may do so at:
              info@onetwosmile.com
            </p>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(NewPrivacyPolicy);
