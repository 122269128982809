export const ctaSate = (lang) => {
  return process.env.REACT_APP_ASSESSMENT_BASE_URL + lang + "/funnel-4-a";
};

export const ctaSupport = (lang) => {
  const CTA =
    lang === "ar"
      ? "https://support.onetwosmile.com/hc/ar/articles/360041065231"
      : "https://support.onetwosmile.com/hc/en-us/articles/360041061071";

  return CTA;
};

export const bookAppointmentLink = (lang, country) => {
  if (country === 'ae' || country === 'uae') {
    return process.env.REACT_APP_ASSESSMENT_BASE_URL + lang + "/funnel-5";
  }
  return (
    process.env.REACT_APP_ASSESSMENT_BASE_URL + lang + "/appointment-request"
  );
};

export const Funnel_a = (queryParams, language) => {
  return (
    process.env.REACT_APP_ASSESSMENT_BASE_URL +
    language +
    "/funnel-3-a" +
    queryParams
  );
};

export const redirectToFunnel = (lang, country) => {
  let currentUrlParams = window.location.search
  ? `${window.location.search}?country=${country}&key=OneTowSmile`
  : `?country=${country}&key=OneTowSmile`;
  if (country === 'ae' || country === 'uae') {
    return ( process.env.REACT_APP_ASSESSMENT_BASE_URL + 
    lang + 
    "/funnel-5" + 
    currentUrlParams)
  }
  return (process.env.REACT_APP_ASSESSMENT_BASE_URL + lang + "/funnel-4-a" + currentUrlParams)
}
