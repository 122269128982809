import React from "react";
import { withRouter } from "react-router-dom";

import LocalizedStrings from "react-localization";
import { ctaSate } from "../../CTA_helper";

import styles from "./join_program.module.scss";

let englishStrings = require("./Join_program_langs_en.json");
let arabicStrings = require("./Join_program_langs_ar.json");
let strings = new LocalizedStrings({ en: englishStrings, ar: arabicStrings });

function JoinProgram(props) {
  console.log(props, "props....");
  var locale =
    props.match.params.locale &&
    props.match.params.locale.split("-").length === 2
      ? props.match.params.locale
      : "en-us";

  let language = locale ? locale.split("-")[0] : "en";
  let country = locale ? locale.split("-")[1] : "us";

  strings.setLanguage(language);
  let flipImage = language === "ar" ? "scaleX(-1)" : "none";

  return (
    <div className={styles.container}>
      <div></div>
      <div>
        {" "}
        <section className={styles.section1_grid}>
          {/* <div></div> */}
          <div>
            <div className={styles.section_1_header}>
              {strings.section_1_header}
            </div>
            <div className={styles.section_1_description_1}>
              {strings.section_1_description_1}
            </div>
            <div className={styles.section_1_description_2}>
              {strings.section_1_description_2}
            </div>

            <a
              className={`${styles.get_started_btn} callToAction-menu`}
              href={
                ctaSate(language) +
                (sessionStorage.getItem("searchParams")
                  ? sessionStorage.getItem("searchParams")
                  : "?country=" + strings.url_country_name_from_code[country])
              }
            >
              {strings.get_started_btn}
            </a>
          </div>
          <div className={styles.section1_img}>
            <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}/hands-pg.png`} />
          </div>

          {/* <div></div> */}
        </section>
        <section>
          <div className={styles.how_it_works_grid}>
            <div className={styles.how_it_works}>{strings.how_it_works}</div>
            <div></div>
          </div>

          <div className={styles.featurs}>
            {strings.featurs.map((value, index) => {
              return (
                <div key={index} className={styles.featur_item}>
                  <img
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${value.img}`}
                  />
                  <p className={styles.title}>{value.title}</p>
                </div>
              );
            })}
          </div>
        </section>
      </div>
      <div></div>
    </div>
  );
}

export default withRouter(JoinProgram);
