import React, { useRef } from "react";
import LocalizedStrings from "react-localization";
import { ctaSupport } from "../CTA_helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
let englishStrings = require("./Banner_langs_en.json");
let arabicStrings = require("./Banner_langs_ar.json");

let strings = new LocalizedStrings({ en: englishStrings, ar: arabicStrings });
const Banner = props => {
  const banner = useRef(null);
  const Ref = useRef("free_appointment_announcement");
  const { language } = props;
  strings.setLanguage(language);

  const onCloseBanner = () => {
    props.onHandleBanner();
    banner.current.style.display = "none";
  };
  return (
    <div
      ref={banner}
      className="Banner_Wrapper"
      style={{
        direction: language === "ar" ? "rtl" : "ltr"
      }}
    >
      <div className="Banner_content">
        <span style={{ paddingInlineEnd: 10 }}>
          <FontAwesomeIcon icon={faExclamationCircle} size="1x" />
        </span>
        {strings.covid_19_announcment}
        <span className="Banner_Styled_Link">
          <a
            href={
              ctaSupport(language) +
              (sessionStorage.getItem("searchParams")
                ? sessionStorage.getItem("searchParams")
                : "?country=" +
                  strings.url_country_name_from_code[
                    localStorage.getItem("country")
                  ])
            }
            ref={Ref}
          >
            {strings.covid_19_announcment_cta}
          </a>
        </span>
      </div>
      <div className="Banner_CloseButton">
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onCloseBanner}
        >
          <g transform="translate(4 4.5)" fill="#000" fillRule="evenodd">
            <rect
              transform="rotate(45 11 11)"
              x="-3"
              y="10"
              width="28"
              height="2"
              rx="1"
            ></rect>
            <rect
              transform="rotate(-45 11 11)"
              x="-3"
              y="10"
              width="28"
              height="2"
              rx="1"
            ></rect>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Banner;
