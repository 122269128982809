import React from "react";
// import { render, hydrate } from "react-dom";
// import {Helmet} from "react-helmet";
// import { render } from "react-snap/shot";
import { render } from "react-snapshot";
import "./index.scss";
import { rootReducer } from "./reducers";
import App from "./App";
import { createStore } from "redux";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
let store = createStore(rootReducer);

// using react-snap to pre-render our content and deploy the resulting static file
const rootElement = document.getElementById("root");

render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
);

// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <Provider store={store}>
//       <App />
//     </Provider>,
//     rootElement
//   );
// } else {
//   render(
//     <Provider store={store}>
//       <App />
//     </Provider>,
//     rootElement
//   );
// }

// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById("root")
// );
// render(<App />, document.getElementById("root"));
// const helmet = Helmet.renderStatic();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
