import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
// import ReactGA from "react-ga";
//import ReactPixel from "react-facebook-pixel";
// import { Provider } from "react-redux";
import Modal from "react-modal";
import queryString from "query-string";

import {
  faStar,
  faPlus,
  faMinus,
  faGlobe,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";
import ScrollToTop from "react-router-scroll-top";

import "./App.scss";
import "./AppDesktop.scss";

// import NavBar from "./components/layout/NavBar";
import NavBar from "./components/layout/New-NavBar";

import Container from "./components/layout/Container";
import Footer from "./components/layout/New-Footer/Footer";
import { connect } from "react-redux";
// import Footer from "./components/layout/Footer";

import Banner from "./components/layout/Banner";
// import CountryPicker from "./components/CountryPicker";

// import AssessmentForm from "./components/pages/AssessmentForm/AssessmentForm";

const AssessmentForm = React.lazy(() =>
  import("./components/pages/AssessmentForm/AssessmentForm")
);
const CountryPicker = React.lazy(() => import("./components/CountryPicker"));

// import store from "./store";

// ReactGA.initialize("UA-105221482-1"); // PRODUCTION
// ReactGA.initialize("UA-147059773-1"); // STAGING

library.add(faStar, faStarEmpty, faPlus, faMinus, faGlobe, faPhoneAlt);

// const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
// const options = {
//   autoConfig: true,
//   debug: false
// };
// ReactPixel.init("281448932334730", advancedMatching, options);

const desktopModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "70vw",
    height: "auto",
    border: "none",
    backgroundColor: "#fafafa",
    zIndex: "999 !important",
  },
};

const mobileModalStyle = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // width: "90vw",
    height: "auto",
    border: "none",
    zIndex: "999 !important",
    backgroundColor: "#fafafa",
  },
};

var modalStyle = function () {
  if (window.innerWidth < 840) {
    return mobileModalStyle;
  } else {
    return desktopModalStyle;
  }
};

const utmCountriesLanguageDictionary = {
  qatar: "en",
  uae: "en",
  saudi: "ar",
  kuwait: "en",
  iraq: "en",
  lebanon: "en",
  jordan: "en",
};

const utmCountriesCodeDictionary = {
  qatar: "qa",
  uae: "ae",
  saudi: "sa",
  kuwait: "kw",
  iraq: "iq",
  lebanon: "lb",
  jordan: "jo",
};

const countriesDictionary = {
  jo: "jordan",
  sa: "saudi",
  ae: "uae",
  kw: "kuwait",
  qa: "qatar",
  us: "us",
  eu: "eu",
  iq: "iraq",
  lb: "lebanon",
};

const utmCountriesCodeLocale = {
  jo: "jo",
  sa: "sa",
  ae: "ae",
  kw: "kw",
  qa: "qa",
  us: "us",
  eu: "eu",
  iq: "iq",
  lb: "lb",
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      isValidLocale: false,
      isBannerExist: false,
    };
    // isBannerExist: !sessionStorage.banner
    // ? true
    // : JSON.parse(sessionStorage.banner),
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {}

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  showCountrySelector = () => {
    console.log("show country picker");
    this.openModal();
  };

  changeCountry = (selectedCountry) => {
    if (selectedCountry === "sa") {
      localStorage.setItem("language", "ar");
    } else {
      localStorage.setItem("language", "en");
    }

    localStorage.setItem("showPopup", false);
    localStorage.setItem("country", selectedCountry);

    if (sessionStorage.getItem("searchParams")) {
      const parsed = queryString.parse(sessionStorage.getItem("searchParams"));
      parsed.country = selectedCountry;
      sessionStorage.setItem(
        "searchParams",
        "?" + queryString.stringify(parsed)
      );
    }

    const originalPath = window.location.pathname;
    var pathElements = originalPath.split("/");
    const originalLocalePath = pathElements[1];
    this.closeModal();
    if (originalLocalePath) {
      // var originalLocaleArray = originalLocalePath.split("-");
      const newLocalePath =
        localStorage.getItem("language") + "-" + selectedCountry;
      const newPath = originalPath.replace(originalLocalePath, newLocalePath);
      window.location.pathname = newPath;
    }
  };

  validLocalePath = (test) => {
    let langs =
      test &&
      test.split("-").length === 2 &&
      (test.split("-")[0] === "en" || test.split("-")[0] === "ar");
    let country =
      test &&
      test.split("-").length === 2 &&
      !!utmCountriesCodeLocale[test.split("-")[1]];
    if (langs && country) {
      if (country === "ae") {
        localStorage.setItem("country", "ae");
      }
      return true;
    }
    return false;
  };

  validateCountryLocal = (testCountery) => {
    return (
      !!testCountery &&
      testCountery.length === 2 &&
      !!utmCountriesCodeLocale[testCountery]
    );
  };

  componentWillMount() {
    // if (!sessionStorage.getItem("banner")) {
    //   sessionStorage.setItem("banner", true);
    //   this.setState({ isBannerExist: true });
    // }
    if (window.location.search) {
      sessionStorage.setItem("searchParams", window.location.search);
    }

    const originalPath = window.location.pathname;
    var pathElements = originalPath.split("/");
    const originalLocalePath = pathElements[1];
    var originalLocaleArray = originalLocalePath.split("-");
    if (this.validLocalePath(originalLocalePath)) {
      localStorage.setItem("language", originalLocaleArray[0]);
      localStorage.setItem(
        "country",
        originalLocaleArray[1] === "ae" ? "ae" : originalLocaleArray[1]
      );
      this.setState({
        isValidLocale: true,
      });
    } else {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const url_country = url.searchParams.get("country");

      if (url_country && utmCountriesCodeDictionary[url_country]) {
        localStorage.setItem(
          "language",
          utmCountriesLanguageDictionary[url_country]
        );
        localStorage.setItem(
          "country",
          this.validateCountryLocal(utmCountriesCodeDictionary[url_country])
            ? utmCountriesCodeDictionary[url_country] === "ae"
              ? "ae"
              : utmCountriesCodeDictionary[url_country]
            : "us"
        );
        this.setState({ isValidLocale: true });
      } else if (
        window.location.pathname !== "/" &&
        this.validLocalePath(originalLocalePath) === false
      ) {
        this.setState({ isValidLocale: false });
        localStorage.setItem("showPopup", "no");
        localStorage.setItem("language", "en");
        localStorage.setItem("country", "us");
      } else if (
        localStorage.getItem("country") &&
        localStorage.getItem("language") &&
        countriesDictionary[localStorage.getItem("country")] &&
        this.validLocalePath(originalLocalePath)
      ) {
        console.log("country and language already saved");
        window.location.pathname =
          localStorage.getItem("language") +
          "-" +
          localStorage.getItem("country");
        localStorage.setItem(
          "country",
          this.validateCountryLocal(localStorage.country)
            ? localStorage.country === "us"
              ? "us"
              : localStorage.country
            : "us"
        );
        this.setState({ isValidLocale: true });
        localStorage.setItem("showPopup", "no");
      } else if (
        window.location.pathname === "/" &&
        localStorage.country &&
        localStorage.language
      ) {
        window.location.pathname =
          localStorage.getItem("language") +
          "-" +
          localStorage.getItem("country");
        localStorage.setItem(
          "country",
          this.validateCountryLocal(localStorage.country)
            ? localStorage.country === "ae"
              ? "ae"
              : localStorage.country
            : "us"
        );
        this.setState({ isValidLocale: true });
        localStorage.setItem("showPopup", "no");
      } else {
        console.log("assuming default locale");
        localStorage.setItem("showPopup", "yes");

        this.setState({ isValidLocale: true });
        window.location.pathname = "en-sa";
      }
    }
  }

  componentDidMount() {
    if (
      !localStorage.getItem("country") ||
      localStorage.getItem("showPopup") === "yes"
    ) {
      console.log("showpopup: " + localStorage.getItem("showPopup"));
      console.log("country: " + localStorage.getItem("country"));
      this.showCountrySelector();
    }
  }

  onHandleBanner = () => {
    sessionStorage.setItem("banner", false);
    this.setState({ isBannerExist: false });
  };
  render() {
    return (
      // <Provider store={store}>
      <div>
        {/* {JSON.parse(sessionStorage.getItem("banner")) && (
          <Banner
            language={localStorage.language}
            onHandleBanner={this.onHandleBanner}
          />
        )} */}

        <Router forceRefresh={true}>
          <ScrollToTop>
            <Suspense fallback={<div></div>}>
              <div className={window.innerWidth > 839 ? "main-container" : ""}>
                <div />
                <div className="App grid2">
                  <div className="zIndex">
                    <Modal
                      isOpen={this.state.modalIsOpen}
                      onAfterOpen={this.afterOpenModal}
                      onRequestClose={this.closeModal}
                      style={modalStyle()}
                      contentLabel="Example Modal"
                    >
                      <CountryPicker changeCountry={this.changeCountry} />
                    </Modal>
                  </div>
                  <Switch>
                    <Route
                      path="/:locale/assessment"
                      render={(props) => <AssessmentForm language="ar" />}
                    />
                    <Route
                      path="/assessment"
                      render={(props) => <AssessmentForm language="en" />}
                    />
                  </Switch>

                  <Switch>
                    <Route path="/:locale/assessment" render={() => null} />
                    <Route
                      path="/:locale"
                      render={(props) => (
                        <NavBar
                          validateCountryLocal={this.validateCountryLocal}
                          showCountrySelector={this.showCountrySelector}
                          isBannerExist={this.state.isBannerExist}
                        />
                      )}
                    />
                    <Route
                      path="/"
                      render={(props) => (
                        <NavBar
                          isBannerExist={this.state.isBannerExist}
                          validateCountryLocal={this.validateCountryLocal}
                          showCountrySelector={this.showCountrySelector}
                        />
                      )}
                    />
                  </Switch>

                  <Switch>
                    <Route path="/:locale/assessment" render={() => null} />
                    <Route
                      path="/:locale"
                      render={(props) => (
                        <Container
                          isValidLocale={this.state.isValidLocale}
                          isBannerExist={this.state.isBannerExist}
                        />
                      )}
                    />
                    <Route
                      path="/"
                      render={(props) => (
                        <Container
                          isValidLocale={this.state.isValidLocale}
                          isBannerExist={this.state.isBannerExist}
                        />
                      )}
                    />
                  </Switch>

                  <Switch>
                    <Route path="/:locale/assessment" render={() => null} />
                    <Route
                      path="/:locale"
                      render={(props) => (
                        <Footer
                          validateCountryLocal={this.validateCountryLocal}
                        />
                      )}
                    />
                    <Route
                      path="/"
                      render={(props) => (
                        <Footer
                          validateCountryLocal={this.validateCountryLocal}
                        />
                      )}
                    />
                  </Switch>
                </div>
                <div />
              </div>
            </Suspense>
          </ScrollToTop>
        </Router>
      </div>
      // </Provider>
    );
  }
}

const mapStateToProps = ({ FooterTootgle }) => {
  const { Show } = FooterTootgle;
  return { Show };
};

export default connect(mapStateToProps)(App);
