import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import LocalizedStrings from "react-localization";
import style from "./Footer_Style.module.scss";
import queryString from "query-string";

let englishStrings = require("./Footer_langs_en.json");
let arabicStrings = require("./Footer_langs_ar.json");

let strings = new LocalizedStrings({ en: englishStrings, ar: arabicStrings });

export class Footer extends Component {
  changeCountry = (selectedCountry) => {
    if (selectedCountry === "sa") {
      localStorage.setItem("language", "ar");
    } else {
      localStorage.setItem("language", "en");
    }

    // localStorage.setItem("country", selectedCountry);

    if (sessionStorage.getItem("searchParams")) {
      const parsed = queryString.parse(sessionStorage.getItem("searchParams"));
      parsed.country = selectedCountry;
      sessionStorage.setItem(
        "searchParams",
        "?" + queryString.stringify(parsed)
      );
    }

    const originalPath = window.location.pathname;
    var pathElements = originalPath.split("/");
    const originalLocalePath = pathElements[1];
    if (originalLocalePath) {
      // var originalLocaleArray = originalLocalePath.split("-");
      const newLocalePath =
        localStorage.getItem("language") + "-" + selectedCountry;
      const newPath = originalPath.replace(originalLocalePath, newLocalePath);
      //  window.location.pathname = newPath;
      return newPath;
    }
  };

  render() {
    var locale =
      this.props.match.params.locale &&
      this.props.match.params.locale.split("-").length === 2
        ? this.props.match.params.locale
        : "en-us";

    let country =
      this.props.validateCountryLocal(locale.split("-")[1]) === "ae"
        ? "ae"
        : this.props.validateCountryLocal(locale.split("-")[1])
        ? locale.split("-")[1] === "ae"
          ? "ae"
          : locale.split("-")[1]
        : this.props.validateCountryLocal(localStorage.country)
        ? localStorage.country === "ae"
          ? "ae"
          : "ae"
        : "ae";

    let language =
      country == "us" || country === "eu"
        ? "en"
        : locale
        ? locale.split("-")[0]
        : "en";
    let direction = language === "ar" ? "rtl" : "ltr";
    console.log(locale.split("-")[0], 'footer')
    strings.setLanguage(language);
    return (
      <div
        className={`${style.footer_container} grid`}
        style={{ direction: direction }}
      >
        <div className={style.footer_content}>
          {/* First column => Support  */}
          <div className={style.footer_column}>
            <span className={style.footer_column_header}>
              {strings.footer_support}
            </span>

            <div className={style.contacts_item}>
              <img
                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/email.svg`}
                alt="email"
                className={style.contacts_icon}
              />
              <a
                className={style.desktop_menu_link}
                href="mailto:info@onetwosmile.com"
              >
                info@onetwosmile.com
              </a>
            </div>
            <div className={style.contacts_item}>
            <FontAwesomeIcon
                  icon={faWhatsapp}
                  color="white"
                  style={{
                    fontSize: "1.5em",
                    visibility: strings.phone_number_locale[
                      localStorage.getItem("country")
                    ]
                      ? "visible"
                      : "hidden",
                  }}
              />
              {/* <img
                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/call.svg`}
                alt="phone"
                className={style.contacts_icon}
                style={{
                  visibility: strings.phone_number_locale[
                    localStorage.getItem("country")
                  ]
                    ? "visible"
                    : "hidden",
                }}
              /> */}
              <a
                className={style.desktop_menu_link}
                href={`tel:${strings.phone_number_locale[country]}`}
                style={{
                  direction: "ltr",
                  textAlign: language === "ar" ? "end" : "start",
                }}
              >
                {strings.phone_number_locale[country]}
              </a>
            </div>

            <div className={style.contacts_item}>
              <span
                className={style.desktop_menu_link}
                style={{
                  direction: "ltr",
                  textAlign: language === "ar" ? "end" : "start",
                }}
              >
                <b>{strings.working_hours_locale[country].days}</b>{" "}
                {strings.working_hours_locale[country].time}
              </span>
            </div>

            <div className={style.contacts_item}>
              <a
                className={style.desktop_menu_link}
                href="https://support.onetwosmile.com/hc/en-us"
                target="_blank"
                rel="noopener noreferrer"
              >
                {strings.footer_faq}
              </a>
            </div>
            <div className={style.contacts_item}>
              <a
                className={style.desktop_menu_link}
                href="https://support.onetwosmile.com/hc/en-us/requests/new"
                target="_blank"
                rel="noopener noreferrer"
              >
                {strings.footer_chat_with_us}
              </a>
            </div>
            <div className={style.horizontal_line} />

            <div className={style.cta_ask_quiestion_container}>
              <a
                href="https://support.onetwosmile.com/hc/en-us/requests/new"
                target="_blank"
                rel="noopener noreferrer"
                className={style.cta_ask_quiestion_containe}
              >
                {strings.footer_ask_qeustion_cta}
              </a>
            </div>
          </div>
          <div className={style.footer_links_container}>
            {/* Second column => OneTowSmile-Aligner */}
            <div className={style.footer_column}>
              <span className={style.footer_column_header}>
                {strings.footer_oneTwoSmile_invisalign}
              </span>
              <div className={style.footer_links}>
                <div>
                  <NavLink
                    className={style.footer_menu_link}
                    to={"/" + language + "-" + country + "/how-it-works/"}
                  >
                    {strings.footer_howItWorks}
                  </NavLink>

                  <Link
                    className={style.footer_menu_link}
                    to={"/" + language + "-" + country + "/results/"}
                  >
                    {strings.footer_results}
                  </Link>

                  <Link
                    className={style.footer_menu_link}
                    to={"/" + language + "-" + country + "/price/"}
                  >
                    {strings.footer_price}
                  </Link>
                </div>
                <div>
                  <Link
                    className={style.footer_menu_link}
                    to={"/" + language + "-" + country + "/complete-care/"}
                  >
                    {strings.complete_care}
                  </Link>
                </div>
                <div>
                  <Link
                    className={style.footer_menu_link}
                    to={"/" + language + "-" + country + "/location/"}
                  >
                    {strings.footer_location}
                  </Link>
                </div>
                <div>
                  <Link
                    className={style.footer_menu_link}
                    to={"/" + language + "-" + country + "/is-it-for-me/"}
                  >
                    {strings.footer_isitforme}
                  </Link>
                </div>
                {/* <div>
                  <a
                    className={style.footer_menu_link}
                    href={process.env.REACT_APP_ASSESSMENT_BASE_URL + language +  "/refer-a-friend"}
                  >
                    {strings.refer_a_friend}
                  </a>
                </div> */}
                <div>
                  <a
                    className={style.footer_menu_link}
                    href={
                      language === "ar"
                        ? process.env.REACT_APP_OUR_GUARANTEE_AR
                        : process.env.REACT_APP_OUR_GUARANTEE_EN
                    }
                  >
                    {strings.our_guarantee}
                  </a>
                </div>
                <div>
                  <Link
                    className={style.footer_menu_link}
                    to={"/" + language + "-" + country + "/our-doctors/"}
                  >
                    {strings.are_you_a_doctor}
                  </Link>
                </div>
              </div>
            </div>
            {/* Second column => OUR COMPANY */}
            <div className={style.footer_column}>
              <span className={style.footer_column_header}>
                {strings.footer_our_company}
              </span>
              <div className={style.footer_links}>
                <div>
                  <NavLink
                    className={style.footer_menu_link}
                    to={"/" + language + "-" + country + "/about-us/"}
                  >
                    {strings.footer_about_us}
                  </NavLink>

                  {/* <a
                    className={style.footer_menu_link}
                    href="https://blog.1-2smile.com/en/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {strings.footer_blog}
                  </a> */}
                  <a
                    className={style.footer_menu_link}
                    href="https://www.instagram.com/onetwosmiledental/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </div>
                <div>
                  <a
                    className={style.footer_menu_link}
                    href="https://www.youtube.com/channel/UCECCVXhBEyDh6b8J92lWXWw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    YouTube
                  </a>
                </div>
                <div>
                  <a
                    className={style.footer_menu_link}
                    href="https://www.facebook.com/onetwosmiledental"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </div>
                <div>
                  <a
                    className={style.footer_menu_link}
                    href="https://twitter.com/OneTwoSmile"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* Third Coulmn =>  RESOURCES*/}
          <div className={style.footer_column}>
            <span className={style.footer_column_header}>
              {strings.footer_resources}
            </span>

            <div className={style.footer_resources_countainer}>
              <Link
                className={style.footer_resource_link}
                to={"/" + language + "-" + country + "/lp/other-aligners/"}
              >
                <div className={style.footer_resources_content}>
                  {strings.footer_comparison}

                  <div
                    className={
                      language === "ar"
                        ? style.footer_resource_arrow_ar
                        : style.footer_resource_arrow_en
                    }
                  />
                </div>
              </Link>
              <div className={style.resource_horizontal_line} />
            </div>
            <span className={style.footer_column_header}>
              {strings.download_app}
            </span>

            <div className={style.download_app_container}>
              <img
                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/app_store.png`}
                alt=""
                className={style.intro_oneTwoSmile_icons}
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/jo/app/onetwosmile/id1532702937"
                  )
                }
              />
              <img
                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/google_play.png`}
                alt=""
                className={style.intro_oneTwoSmile_icons}
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.onetwosmile.android.support"
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className={style.countries}>
          {strings.countries.map((value, index) => (
            <div className={style.country} key={index}>
              <Link to={() => this.changeCountry(value.key)}>
                {value.name}{" "}
              </Link>
            </div>
          ))}
        </div>{" "}
        <div id={style.copyright}>
          <div>
            <Link
              to={"/" + language + "-" + country + "/privacy-policy/"}
              rel="nofollow"
            >
              {strings.footer_privacy}
            </Link>
            <Link
              to={"/" + language + "-" + country + "/privacy-policy/"}
              rel="nofollow"
            >
              {strings.footer_tos}
            </Link>
          </div>

          <p>{strings.footer_copyright}</p>
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);
