import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { slide as MobileMenu } from "react-burger-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import MediaQuery from "react-responsive";
import "../../CustomBurgerMenu.scss";
import LocalizedStrings from "react-localization";
import { ctaSate, redirectToFunnel } from "../CTA_helper";

let englishStrings = require("./New-NavBar_langs_en.json");
let arabicStrings = require("./New-NavBar_langs_ar.json");

let strings = new LocalizedStrings({ en: englishStrings, ar: arabicStrings });

export class NavBar extends Component {
  state = {
    menuOpen: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isBannerExist !== this.props.isBannerExist) {
      let overrideBurgerButton = document.getElementsByClassName(
        "bm-burger-button"
      );
      for (var i = 0; i < overrideBurgerButton.length; i++) {
        overrideBurgerButton[i].classList.remove("overrideBurgerButton");
      }
    }
  }

  componentDidMount() {
    let overrideBurgerButton = document.getElementsByClassName(
      "bm-burger-button"
    );
    for (var i = 0; i < overrideBurgerButton.length; i++) {
      overrideBurgerButton[i].classList.add(
        this.props.isBannerExist ? "overrideBurgerButton" : "emptyClass"
      );
    }
  }

  handleStateChange = (state) => {
    this.setState({ menuOpen: state.isOpen });
  };

  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  showCountrySelector = () => {
    this.props.showCountrySelector();
  };

  changeCountry = (selectedCountry) => {
    console.log("selected " + selectedCountry);
  };

  changeLanguage = () => {
    this.closeMenu();
  };

  render() {
    var locale =
      this.props.match.params.locale &&
      this.props.match.params.locale.split("-").length === 2
        ? this.props.match.params.locale
        : "en-us";

    let country =
      this.props.validateCountryLocal(locale.split("-")[1]) === "ae"
        ? "ae"
        : this.props.validateCountryLocal(locale.split("-")[1])
        ? locale.split("-")[1] === "ae"
          ? "ae"
          : locale.split("-")[1]
        : this.props.validateCountryLocal(localStorage.country)
        ? localStorage.country === "ae"
          ? "ae"
          : "ae"
        : "ae";
    let language =
      country == "us" || country === "eu"
        ? "en"
        : locale
        ? locale.split("-")[0]
        : "en";

    let direction = language === "ar" ? "rtl" : "ltr";
    strings.setLanguage(language);

    if (window.zE) {
      window.zE("webWidget", "setLocale", language);
    }

    const languageHidden =
      country === "us" || country === "eu"
        ? { visibility: "hidden !important" }
        : {};
    return (
      
      <div className="navbar grid" style={{ direction: direction }}>
        <div className="desktop-navigation-menu">
          <Link className="logo" to={"/" + language + "-" + country + "/"}>
            <img
              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/onetwosmile-logo.svg`}
              alt=""
              id="logo"
            />
          </Link>
        </div>
        <div />

        <MediaQuery minDeviceWidth={840}>
          <div className="desktop-navigation-menu">
            <div className="language-change-container">
              <div>
                <span>{`${strings.working_days} ${
                  strings.working_hours_locale[localStorage.getItem("country")]
                }`}</span>
              </div>
              
              <span
                className="top-phone-number-container"
                style={{
                  marginInlineEnd: "2em",
                  marginInlineStart: "2em",
                  direction: "ltr",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  style={{
                    color: "#6bcaba",
                    visibility: strings.phone_number_locale[
                      localStorage.getItem("country")
                    ]
                      ? "visible"
                      : "hidden",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    (window.location.href = `tel:${
                      strings.phone_number_locale[
                        localStorage.getItem("country")
                      ]
                    }`)
                  }
                />
                <a
                  href={`tel:${
                    strings.phone_number_locale[localStorage.getItem("country")]
                  }`}
                >
                  {"  "}
                  {strings.phone_number_locale[localStorage.getItem("country")]}
                </a>
              </span>

              <Link
                to={
                  language === "ar"
                    ? this.props.location.pathname.replace("ar", "en")
                    : this.props.location.pathname.replace("en", "ar")
                }
                style={
                  country === "us" || country === "eu"
                    ? { display: "none" }
                    : {}
                }
                onClick={this.changeLanguage}
              >
                {strings.navbar_changeLanguageTitle}
              </Link>
              <span style={languageHidden}> </span>
              {/* <FontAwesomeIcon icon="globe" style={{ color: "#53565a" }} /> */}
              <button onClick={this.showCountrySelector}>
                { 
                  <img
                    className="small-flag"
                    src={
                      `${process.env.REACT_APP_IMAGE_BASE_URL}/flags/` +
                      country +
                      ".png"
                    }
                    alt=""
                  />
                }
              </button>
            </div>
            <div
              className={
                country === "ae"
                  ? "menu-items-container-ae"
                  : "menu-items-container"
              }
            >
              <div className="desktop-menu-item dowpdown">
                <NavLink
                  className="desktop-menu-link dropdown"
                  activeClassName="active-menu-link"
                  to={"/" + language + "-" + country + "/how-it-works/"}
                >
                  <div>
                    {
                      strings.navbar_howItWorks_locale[
                        localStorage.getItem("country")
                      ]
                    }
                  </div>

                  <div className="dropdown-content" style={{ languageHidden }}>
                    <NavLink to={"/" + language + "-" + country + "/location/"}>
                      {
                        strings.navbar_locations_locale[
                          localStorage.getItem("country")
                        ]
                      }
                    </NavLink>

                    <NavLink
                      to={"/" + language + "-" + country + "/about-us/"}
                      // style={languageHidden}
                    >
                      {
                        strings.navbar_aboutUs_locale[
                          localStorage.getItem("country")
                        ]
                      }
                    </NavLink>
                    <NavLink
                      className="desktop-menu-link"
                      activeClassName="active-menu-link"
                      to={"/" + language + "-" + country + "/is-it-for-me/"}
                      // style={languageHidden}
                    >
                    {strings.navbar_isItForMe}
                  </NavLink>
                  </div>
                </NavLink>
              </div>

              <div className="desktop-menu-item">
                <NavLink
                  className="desktop-menu-link"
                  activeClassName="active-menu-link"
                  to={"/" + language + "-" + country + "/results/"}
                >
                  {strings.navbar_results}
                </NavLink>
              </div>

              <div className="desktop-menu-item">
                <NavLink
                  className="desktop-menu-link"
                  activeClassName="active-menu-link"
                  to={"/" + language + "-" + country + "/price/"}
                >
                  {strings.navbar_price}
                </NavLink>
              </div>

              <div className="desktop-menu-item dowpdown">
                <NavLink
                  className="desktop-menu-link dropdown"
                  activeClassName="active-menu-link"
                  to={"/" + language + "-" + country + "/complete-care/"}
                  // style={languageHidden}
                >
                  {strings.navbar_completeCare}

                  {/* <div className="dropdown-content" style={{ languageHidden }}>
                    <NavLink
                      to={"/" + language + "-" + country + "/our-doctors/"}
                      // style={languageHidden}
                    >
                      {strings.navbar_ourDoctors}
                    </NavLink>
                  </div> */}
                </NavLink>
              </div>

              <div className="desktop-menu-item">
                <a
                  className="desktop-menu-link"
                  activeclassname="active-menu-link"
                  href={
                    language === "ar"
                      ? "https://support.onetwosmile.com/hc/ar"
                      : "https://support.onetwosmile.com/hc/en-us"
                  }
                  onClick={() => this.closeMenu()}
                >
                  {strings.navbar_support}
                </a>
                {/* <NavLink
                  className="desktop-menu-link"
                  activeClassName="active-menu-link"
                  to={"/" + language + "-" + country + "/support/"}
                >
                  {strings.navbar_support}
                </NavLink> */}
              </div>

              {/* <div className="desktop-menu-item">
                <NavLink
                  className="desktop-menu-link"
                  activeClassName="active-menu-link"
                  to={"/" + language + "-" + country + "/is-it-for-me/"}
                  // style={languageHidden}
                >
                  {strings.navbar_isItForMe}
                </NavLink>
                <a
                  className="desktop-menu-link"
                  activeclassname="active-menu-link"
                  href={process.env.REACT_APP_ASSESSMENT_BASE_URL + language +  "/refer-a-friend"}
                  onClick={() => this.closeMenu()}
                >
                  {strings.navbar_refer_a_friend}
                </a>
              </div> */}

              {/* {country === "ae" ? (
                <div className="desktop-menu-item">
                  <NavLink
                    className="desktop-menu-link"
                    activeClassName="active-menu-link"
                    to={"/" + language + "-" + country + "/financing/"}
                    // style={languageHidden}
                  >
                    {strings.navbar_financing}
                  </NavLink>
                </div>
              ) : (
                <div style={{ display: "none" }}></div>
              )} */}
              <div className="desktop-menu-item">
                <a
                  className="callToAction-menu"
                  href={
                    // ctaSate(language, country) +
                    // (sessionStorage.getItem("searchParams")
                    //   ? sessionStorage.getItem("searchParams")
                    //   : "?country=" +
                    //     strings.url_country_name_from_code[country])
                    redirectToFunnel(language, country)
                  }
                  
                >
                  {strings.navbar_doIQualify_locale[
                    localStorage.getItem("country")
                  ]}
                </a>
              </div>
            </div>
          </div>
        </MediaQuery>

        <div>
          <MobileMenu
            right
            noOverlay
            isOpen={this.state.menuOpen}
            onStateChange={this.handleStateChange}
          >
            <NavLink
              exact
              className="menu-item menu-inner-page-item"
              activeClassName="active-menu-link"
              to={"/" + language + "-" + country + "/"}
              onClick={() => this.closeMenu()}
            >
              {strings.navbar_home}
            </NavLink>
            <NavLink
              className="menu-item menu-inner-page-item"
              activeClassName="active-menu-link"
              to={"/" + language + "-" + country + "/how-it-works/"}
              onClick={() => this.closeMenu()}
            >
              {
                strings.navbar_howItWorks_locale[
                  localStorage.getItem("country")
                ]
              }
            </NavLink>
            <NavLink
              className="menu-item menu-inner-page-item"
              activeClassName="active-menu-link"
              to={"/" + language + "-" + country + "/complete-care/"}
              onClick={() => this.closeMenu()}
            >
              {strings.navbar_completeCare}
            </NavLink>
            <NavLink
              className="menu-item menu-inner-page-item"
              activeClassName="active-menu-link"
              to={"/" + language + "-" + country + "/our-doctors/"}
              onClick={() => this.closeMenu()}
            >
              {strings.navbar_ourDoctors}
            </NavLink>
            <NavLink
              className="menu-item menu-inner-page-item"
              activeClassName="active-menu-link"
              to={"/" + language + "-" + country + "/results/"}
              onClick={() => this.closeMenu()}
            >
              {strings.navbar_results}
            </NavLink>
            <NavLink
              className="menu-item menu-inner-page-item"
              activeclassname="active-menu-link"
              to={"/" + language + "-" + country + "/price/"}
              onClick={() => this.closeMenu()}
            >
              {strings.navbar_price}
            </NavLink>
            <a
              className="menu-item menu-inner-page-item"
              activeclassname="active-menu-link"
              href={
                language === "ar"
                  ? "https://support.onetwosmile.com/hc/ar"
                  : "https://support.onetwosmile.com/hc/en-us"
              }
              onClick={() => this.closeMenu()}
            >
              {strings.navbar_support}
            </a>

            <NavLink
              className="menu-item menu-inner-page-item"
              activeClassName="active-menu-link"
              to={"/" + language + "-" + country + "/location/"}
              onClick={() => this.closeMenu()}
              style={languageHidden}
            >
              {strings.navbar_locations_locale[localStorage.getItem("country")]}
            </NavLink>
            <NavLink
              className="menu-item menu-inner-page-item"
              activeClassName="active-menu-link"
              to={"/" + language + "-" + country + "/is-it-for-me/"}
              onClick={() => this.closeMenu()}
              style={languageHidden}
            >
              {strings.navbar_isItForMe}
            </NavLink>
            {/* <a
              className="menu-item menu-inner-page-item"
              activeclassname="active-menu-link"
              href={process.env.REACT_APP_ASSESSMENT_BASE_URL + language +  "/refer-a-friend"}
              onClick={() => this.closeMenu()}
            >
              {strings.navbar_refer_a_friend}
            </a> */}
            <NavLink
              className="menu-item menu-inner-page-item"
              activeClassName="active-menu-link"
              to={"/" + language + "-" + country + "/about-us/"}
              onClick={() => this.closeMenu()}
              style={languageHidden}
            >
              {strings.navbar_aboutUs_locale[localStorage.getItem("country")]}
            </NavLink>
            {/* {country === "ae" ? (
                  <NavLink
                    className="menu-item menu-inner-page-item"
                    activeClassName="active-menu-link"
                    to={"/" + language + "-" + country + "/financing/"}
                    onClick={() => this.closeMenu()}
                    style={languageHidden}
                  >
                    {strings.navbar_financing}
                  </NavLink>
                ) : (
                  <div style={{ display: "none" }}>nothing</div>
                )} */}
            <Link
              to={
                language === "ar"
                  ? this.props.location.pathname.replace("ar", "en")
                  : this.props.location.pathname.replace("en", "ar")
              }
              style={
                country === "us" || country === "eu" ? { display: "none" } : {}
              }
              onClick={this.changeLanguage}
              className="menu-item menu-inner-page-item"
            >
              {strings.navbar_changeLanguageTitle}
            </Link>
          </MobileMenu>
        </div>

        <div
          className={
            this.props.isBannerExist
              ? "navbar-mobile-container-with-banner display-mobile"
              : "navbar-mobile-container display-mobile"
          }
          style={{ direction: "ltr" }}
        >
          <div className="navbar-first-container">
            
              <div className="navbar-mobile-flag">
                <button onClick={this.showCountrySelector}>
                  <img
                    src={
                      `${process.env.REACT_APP_IMAGE_BASE_URL}/flags/` +
                      country +
                      ".png"
                    }
                    alt=""
                  />
                </button>
              </div>
           

            <div
              className="navbar-phone"
              style={{
                marginInlineEnd: "0em",
                marginInlineStart: "0em",
                direction: "ltr",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <FontAwesomeIcon
                icon={faWhatsapp}
                style={{
                  color: "#6bcaba",
                  visibility: strings.phone_number_locale[
                    localStorage.getItem("country")
                  ]
                    ? "visible"
                    : "hidden",
                }}
                onClick={() =>
                  (window.location.href =
                    "tel:" +
                    strings.phone_number_locale[
                      localStorage.getItem("country")
                    ])
                }
              />

              <a
                href={
                  "tel:" +
                  strings.phone_number_locale[localStorage.getItem("country")]
                }
              >
                {"  "}
                {strings.phone_number_locale[localStorage.getItem("country")]}
              </a>
            </div>

            <div />
            <span style={{ textAlign: "end" }}>
              {`${strings.working_days} ${
                strings.working_hours_locale[localStorage.getItem("country")]
              }`}
            </span>
          </div>
          <div className="navbar-second-container">
            <div>
              <Link
                className="navbar-logo"
                to={"/" + language + "-" + country + "/"}
              >
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}/onetwosmile-logo.svg`}
                  alt=""
                  id="logo"
                />
              </Link>
            </div>
            <div className="navbar-cta">
              <a
              
                className="callToAction-1-navbar-mobile"
                href={
                  // ctaSate(language) +
                  // (sessionStorage.getItem("searchParams")
                  //   ? sessionStorage.getItem("searchParams")
                  //   : "?country=" +
                  //     strings.url_country_name_from_code[
                  //       localStorage.getItem("country")
                  //     ])
                  redirectToFunnel(language, country)
                }
              >
                {strings.navbar_doIQualify_locale[
                    localStorage.getItem("country")
                  ]}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NavBar);
