import React, { Component, Fragment } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import JoinProgram from "../pages/JoinProgram";
import { NewPrivacyPolicy } from "../pages/NewPrivacyPolicy";

// const Home = React.lazy(() => import("../pages/Home"));
const Home = React.lazy(() => import("../pages/Homepage"));
const CareemCampaign = React.lazy(() =>
  import("../pages/Careem_Promotional_Campaign/Careem")
);

const HowItWorks = React.lazy(() =>
  import("../pages/newHowItWorksPage/HowItWorks")
);
// const Results = React.lazy(() => import("../pages/Results"));
const Results = React.lazy(() => import("../pages/New-reviews-page/reviews"));
const Price = React.lazy(() => import("../pages/NewPricePage"));
// const Price = React.lazy(() => import("../pages/Price"));
const Support = React.lazy(() => import("../pages/Support"));
const PrivacyPolicy = React.lazy(() => import("../pages/PrivacyPolicy"));
const Locations = React.lazy(() => import("../pages/LocationsPage/Locations"));
const CompleteCare = React.lazy(() =>
  import("../pages/CompleteCare-page/CompleteCare")
);
// const Financial = React.lazy(() => import("../pages/Financial"));
const Financial = React.lazy(() => import("../pages/Financing-page"));
const IsItForMe = React.lazy(() => import("../pages/IsItForMe"));
//const OurDoctors = React.lazy(() => import("../pages/OurDoctors"));
const DoctorLandingPage = React.lazy(() =>
  import("../pages/DoctorLp/DoctorLp")
);
const Provider = React.lazy(() => import("../pages/Provider"));
const HowItWorksLP = React.lazy(() => import("../pages/lp/HowItWorksLP"));
const BracesLP = React.lazy(() => import("../pages/lp/BracesLP"));
const InvisalignLP = React.lazy(() => import("../pages/lp/InvisalignLP"));
const DoctorCallLP = React.lazy(() => import("../pages/lp/DoctorCall"));
const VeneersLP = React.lazy(() => import("../pages/lp/VeneersLP"));
const SmilePlus = React.lazy(() => import("../pages/lp/Smile-plusLP"));
const RakBankOffer = React.lazy(() => import("../pages/lp/RakBank-offer"));
const HomepageV1 = React.lazy(() =>
  import("../pages/lp/New_Homepages/HomepageLP_v1")
);
const HomepageV2 = React.lazy(() =>
  import("../pages/lp/New_Homepages/HomepageLP_v2")
);
const HomepageV3 = React.lazy(() =>
  import("../pages/lp/New_Homepages/HomepageLP_v3")
);
const Lifestyle = React.lazy(() =>
  import("../pages/lp/Lifestyle-page/Lifestyle")
);

const HomepageWithAssessment = React.lazy(() =>
  import("../pages/lp/Homepage-with-assessment/Homepage")
);

const NotFound = React.lazy(() => import("../pages/NotFound"));
const SiteMap = React.lazy(() => import("../pages/Sitemap"));
const AboutUs = React.lazy(() => import("../pages/AboutUsPage/About-us"));
const SetPassword = React.lazy(() => import("../pages/SetPassword"));

export class Container extends Component {
  render() {
    var locale =
      this.props.match.params.locale &&
      this.props.match.params.locale.split("-").length === 2
        ? this.props.match.params.locale
        : "en-us";

    let language = locale ? locale.split("-")[0] : "en";
    let country = locale
      ? locale.split("-")[1] === "ae"
        ? "ae"
        : locale.split("-")[1]
      : "ae";
    const validateContryForLPHomepages = (currentCountry) => {
      return ["ae", "sa", "qa", "kw"].includes(currentCountry);
    };

    let direction = language === "ar" ? "rtl" : "ltr";
    if (this.props.match.params.locale === "careem") {
      window.location.href = "/en-ae/careem?promoCode=aed700discount";
    }

    if (language === "ar" && (country == "us" || country == "eu")) {
      return <NotFound />;
    }

    if (
      this.props.match.params.locale === "privacy" ||
      this.props.match.params.locale === "privacy.html"
    ) {
      return <NewPrivacyPolicy />;
    }
    return (
      <div
        className={
          this.props.isBannerExist
            ? "container inner-page-container-with-banner"
            : "container inner-page-container"
        }
        style={{ direction: direction }}
      >
        <Fragment>
          <Switch location={this.props.location}>
            {/* LPs */}
            <Route
              exact
              path="/privacy"
              render={(props) =>
                this.props.isValidLocale ? <NewPrivacyPolicy /> : <NotFound />
              }
            />
            <Route
              exact
              path="/:locale/lp/how-it-works"
              render={(props) =>
                this.props.isValidLocale ? <HowItWorksLP /> : <NotFound />
              }
            />
            <Route
              exact
              path="/:locale/lp/braces"
              render={(props) =>
                this.props.isValidLocale ? <BracesLP /> : <NotFound />
              }
            />
            <Route
              exact
              path="/:locale/lp/other-aligners"
              render={(props) =>
                this.props.isValidLocale ? <InvisalignLP /> : <NotFound />
              }
            />
            <Route
              exact
              path="/:locale/lp/doctor"
              render={(props) =>
                this.props.isValidLocale ? <DoctorCallLP /> : <NotFound />
              }
            />
            <Route
              exact
              path="/:locale/lp/veneers"
              render={(props) =>
                this.props.isValidLocale ? <VeneersLP /> : <NotFound />
              }
            />
            <Route
              exact
              path="/:locale/lp/smile-plus"
              render={(props) =>
                this.props.isValidLocale ? <SmilePlus /> : <NotFound />
              }
            />
            <Route
              exact
              path="/:locale/lp/rakbank-offer"
              render={(props) =>
                this.props.isValidLocale ? <RakBankOffer /> : <NotFound />
              }
            />

            <Route
              exact
              path="/:locale/lp/homepage_v1"
              render={(props) =>
                this.props.isValidLocale ? (
                  validateContryForLPHomepages(country) ? (
                    <HomepageV1 />
                  ) : (
                    <Redirect to={`/${language}-${country}`} />
                  )
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              exact
              path="/:locale/lp/homepage_v2"
              render={(props) =>
                this.props.isValidLocale ? (
                  validateContryForLPHomepages(country) ? (
                    <HomepageV2 />
                  ) : (
                    <Redirect to={`/${language}-${country}`} />
                  )
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              exact
              path="/:locale/lp/homepage_v3"
              render={(props) =>
                this.props.isValidLocale ? (
                  validateContryForLPHomepages(country) ? (
                    <HomepageV3 />
                  ) : (
                    <Redirect to={`/${language}-${country}`} />
                  )
                ) : (
                  <NotFound />
                )
              }
            />

            <Route
              exact
              path="/:locale/lp/homepage_survey"
              render={(props) =>
                this.props.isValidLocale ? (
                  <HomepageWithAssessment />
                ) : (
                  <NotFound />
                )
              }
            />

            <Route
              exact
              path="/:locale/lp/lifestyle"
              render={(props) =>
                this.props.isValidLocale ? <Lifestyle /> : <NotFound />
              }
            />

            {/* Pages */}
            <Route
              exact
              path="/:locale"
              render={(props) =>
                this.props.isValidLocale ? <Home /> : <NotFound />
              }
            />
            <Route exact path="/" render={(props) => <Home />} />
            <Route
              exact
              path={"/:locale/careem"}
              render={(props) => <CareemCampaign />}
            />
            <Route
              exact
              path="/:locale/sitemap"
              render={(props) =>
                this.props.isValidLocale ? <SiteMap /> : <NotFound />
              }
            />
            <Route exact path="/sitemap" render={(props) => <SiteMap />} />
            <Route
              exact
              path="/:locale/how-it-works"
              render={(props) =>
                this.props.isValidLocale ? <HowItWorks /> : <NotFound />
              }
            />
            <Route
              exact
              path="/how-it-works"
              render={(props) => <HowItWorks />}
            />

            <Route
              exact
              path="/:locale/results"
              render={(props) =>
                this.props.isValidLocale ? <Results /> : <NotFound />
              }
            />
            <Route exact path="/results" render={(props) => <Results />} />

            <Route
              exact
              path="/:locale/price"
              render={(props) =>
                this.props.isValidLocale ? (
                  country === "ae" ? (
                    /* <Financial /> */ <Price />
                  ) : (
                    <Price />
                  )) 
                  : (
                  <NotFound />
                )
              }
            />
            <Route exact path="/price" render={(props) => <Price />} />

            <Route
              exact
              path="/:locale/support"
              render={(props) =>
                this.props.isValidLocale ? <Support /> : <NotFound />
              }
            />
            <Route exact path="/support" render={(props) => <Support />} />

            <Route
              exact
              path="/:locale/privacy-policy"
              render={(props) =>
                this.props.isValidLocale ? <PrivacyPolicy /> : <NotFound />
              }
            />
            <Route
              exact
              path="/privacy-policy"
              render={(props) => <PrivacyPolicy />}
            />
            <Route
              exact
              path="/:locale/location"
              render={(props) =>
                this.props.isValidLocale ? <Locations /> : <NotFound />
              }
            />
            <Route exact path="/location" render={(props) => <Locations />} />

            <Route
              exact
              path="/:locale/financing"
              render={(props) =>
                this.props.isValidLocale ? <Financial /> : <NotFound />
              }
            />
            <Route exact path="/financing" render={(props) => <Financial />} />

            <Route
              exact
              path="/:locale/complete-care"
              render={(props) =>
                this.props.isValidLocale ? <CompleteCare /> : <NotFound />
              }
            />
            <Route
              exact
              path="/complete-care"
              render={(props) => <CompleteCare />}
            />

            <Route
              exact
              path="/:locale/about-us"
              render={(props) =>
                this.props.isValidLocale ? <AboutUs /> : <NotFound />
              }
            />
            <Route exact path="/about-us" render={(props) => <AboutUs />} />

            <Route
              exact
              path="/:locale/is-it-for-me"
              render={(props) =>
                this.props.isValidLocale ? <IsItForMe /> : <NotFound />
              }
            />
            <Route
              exact
              path="/is-it-for-me"
              render={(props) => <IsItForMe />}
            />

            <Route
              exact
              path="/:locale/join-program"
              render={(props) =>
                /*this.props.isValidLocale ? <JoinProgram /> :*/ <NotFound />
              }
            />
            <Route
              exact
              path="/join-program"
              render={(props) => /*<JoinProgram />*/  <NotFound />}
            />

            <Route
              exact
              path="/:locale/our-doctors"
              render={(props) =>
                this.props.isValidLocale ? <DoctorLandingPage /> : <NotFound />
              }
            />
            <Route
              exact
              path="/our-doctors"
              render={(props) => <DoctorLandingPage />}
            />
            <Route
              exact
              path="/:locale/become-a-provider"
              render={(props) =>
                this.props.isValidLocale ? <Provider /> : <NotFound />
              }
            />
            <Route
              exact
              path="/become-a-provider"
              render={(props) => <Provider />}
            />
            <Route
              exact
              path="/:locale/set-password"
              render={(props) =>
                this.props.isValidLocale ? <SetPassword /> : <NotFound />
              }
            />
            <Route
              exact
              path="/:locale/:anythingElse"
              render={(props) => <NotFound />}
            />
            <Route path="/" render={(props) => <NotFound />} />
          </Switch>
        </Fragment>
      </div>
    );
  }
}

// const Wrapper = styled.div`
//   .fade-enter {
//     opacity: 0.01;
//   }

//   .fade-enter.fade-enter-active {
//     opacity: 1;
//     transition: opacity 300ms ease-in;
//   }

//   .fade-exit {
//     opacity: 1;
//   }

//   .fade-exit.fade-exit-active {
//     opacity: 0.01;
//     transition: opacity 300ms ease-in;
//   }
// `;

export default withRouter(Container);
